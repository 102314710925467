import { Heading } from '@chakra-ui/react'

export const FooterHeading = (props) => (
    <Heading
        as="h4"
        color="gray.400"
        fontSize="xs"
        fontWeight="semibold"
        textTransform="uppercase"
        letterSpacing="wider"
        {...props}
    />
)