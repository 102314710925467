import {Box, Flex} from '@chakra-ui/react'

export const Layout = ({topBar, sideBar, page, footer, authModal}) => {
    return (
        <>
            {topBar}
            <Box height="calc(100vh - 290px)" overflow="hidden">
                <Flex h="full" id="app-container" overflow='hidden'>
                    {sideBar ? sideBar : null}
                    {page}
                </Flex>
            </Box>
            {footer ? footer : null}
            {authModal ? authModal : null}
        </>
    )
}