import {Box, Text} from "@chakra-ui/react";

export const TextBox = ({text, bg}) => {
    return (
        <Box>
            <Text
                fontSize={{
                    md: "lg",
                }}
                width="fit-content"
                p={1}
                px={6}
                bg={bg ? bg : 'black'}
                color='white'
            >
                {text}
            </Text>
        </Box>
    )
}