export const handleSignIn = async (authStore, email, password) => {
    try {
        await authStore.signIn({email, password})
        return true
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        return false
    }
}

export const handleSignUp = async (authStore, email, password) => {
    try {
        await authStore.signUp({email, password})
        return true
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
        return false
    }
}