import {Box, Image} from "@chakra-ui/react";
import {NavLink} from "react-router";
import logo from './krfts_logo.png'

export const Logo = (props) => {
    return (
        <NavLink to="/" end>
            <Box {...props}>
                <Image src={logo}/>
            </Box>
        </NavLink>
    )
}