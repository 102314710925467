import {
    VStack,
    HStack,
    Box,
    Text,
    Icon,
    Square,
} from '@chakra-ui/react'

import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {ProductImage} from "./product-image";
import {RemoveButton} from "@krfts/product-ui-library";
import {IoAdd} from "react-icons/io5";

export const SourcePhotos = ({sourceImages, onImageRemoved, onFileDrop}) => {
    return (
        <VStack spacing={6}>
            <Text w='80%' fontSize='sm' align='center'>Add between 3 to 5 images of the product from various angles (front, both sides,
                back, 3/4 view). Keep the background as simple as possible.</Text>

            <HStack spacing={4}>
                {sourceImages.map((image, i) => (
                    <Box position='relative' key={i}>
                        <ProductImage image={image} onImageRemoved={onImageRemoved}/>
                        <RemoveButton position='absolute' top={-0.5} right={-0.5} onClick={() => {
                            onImageRemoved(image)
                        }}/>
                    </Box>
                ))}
                {
                    sourceImages.length < 5 ?
                        <AssetDropzone onFileDrop={onFileDrop} assetType='data' boxSize='100px'>
                            <Square size="10">
                                <Icon as={IoAdd} boxSize="10" color="black"/>
                            </Square>
                        </AssetDropzone>
                        : null
                }
            </HStack>
            {sourceImages.length < 3 ?
                <Text w='80%' fontSize='xs' align='center'>Add at least {3 - sourceImages.length} more images.</Text>
                :
                <Text w='80%' fontSize='xs' align='center'>&nbsp;</Text>
            }
        </VStack>
    )
}