import {
    AspectRatio,
    Box,
    VStack,
    HStack,
    Image,
    Skeleton,
    Stack,
    Text,
    Icon,
} from '@chakra-ui/react'
import {IoFilmOutline, IoImageOutline} from "react-icons/io5";

export const ProductCard = (props) => {
    const {product} = props
    return (
        <Stack direction='column' spacing="4">
            <Box bg='black' p={6} cursor='pointer'>

                <Stack direction='column' spacing={4}>
                    <AspectRatio ratio={1}>
                        <Image
                            src={product.imageUrl}
                            alt={product.name}
                            draggable="false"
                            fallback={<Skeleton/>}
                        />
                    </AspectRatio>

                    <VStack align='left'>
                        <Text
                            color='white'
                            fontSize="xs"
                            fontWeight="semibold"
                            letterSpacing="wider"
                            textTransform="uppercase"
                        >
                            {product.name}
                        </Text>

                        <HStack spacing={4}>
                            <HStack spacing={1}>
                                <Icon as={IoImageOutline} color='white'/>
                                <Text
                                    color='white'
                                    fontSize="xs"
                                    textTransform="uppercase"
                                >
                                    {product.imagesGenerated}
                                </Text>
                            </HStack>

                            <HStack spacing={1}>
                                <Icon as={IoFilmOutline} color='white'/>
                                <Text
                                    color='white'
                                    fontSize="xs"
                                    textTransform="uppercase"
                                >
                                    {product.videosGenerated}
                                </Text>
                            </HStack>
                        </HStack>

                    </VStack>
                </Stack>

            </Box>
        </Stack>
    )
}