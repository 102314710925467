import {CoreModal} from "@krfts/core-modal";
import {
    Center,
    Container,
    Stack,
    Button,
} from "@chakra-ui/react";
import {useState, useRef, useEffect} from "react";
import {Metadata} from "./metadata";
import {SourcePhotos} from "./source-photos";
import {createProduct} from "../../data"
import {useAuthStore} from "@krfts/product-state";

export const NewProductModal = ({showModal, setShowModal}) => {

    const [isCreating, setIsCreating] = useState(false);

    const [productName, setProductName] = useState("");
    const [productImage, setProductImage] = useState(null);
    const [sourceImages, setSourceImages] = useState([]);
    const sourceImagesRef = useRef(new Map());

    const authStore = useAuthStore()

    const onFileDropped = (files, type) => {
        files.map((file) => {
            const reader = new FileReader();

            reader.addEventListener("load", function () {
                const newFile = {
                    imageSrc: reader.result,
                    file: file
                }

                if (type === "data") {
                    if (!sourceImagesRef.current.has(file.name) && sourceImagesRef.current.size < 5) {
                        sourceImagesRef.current.set(file.name, newFile);
                    }

                    setSourceImages(Array.from(sourceImagesRef.current.values()));
                } else if (type === "metadata") {
                    setProductImage(newFile);
                } else if (type === "3d") {
                    // setUser3DModel(newFile);
                }
            }, false);

            if (file) reader.readAsDataURL(file);
        })
    }

    const onSourceImageRemoved = (image) => {
        sourceImagesRef.current.delete(image.file.name);
        setSourceImages(Array.from(sourceImagesRef.current.values()));
    }

    const onGenerateClicked = async () => {
        setIsCreating(true)
        const idToken = await authStore.currentUser.getIdToken(true)
        const response = await createProduct(productName, productImage, sourceImages, idToken)

        console.log(response)

        // should probably navigate to the newly created product
        setProductName("")
        setProductImage(null)
        setSourceImages([])
        sourceImagesRef.current = new Map()
        setIsCreating(false)
        setShowModal(false)
    }

    return (
        <CoreModal
            isOpen={showModal}
            onCloseClicked={() => {
                setShowModal(false)
            }}
            size='4xl'
            title='Create a New Product'
            buttonComponents={
                <Button
                    bg='yellow.300'
                    _hover={{ bg: 'yellow.100' }}
                    mr={3}
                    disabled={sourceImages.length < 3}
                    isLoading={isCreating}
                    onClick={() => onGenerateClicked()}
                >
                    Create Product
                </Button>
            }
        >

            <Center w='100%' h='100%'>
                <Container maxW="4xl" py={{base: '12', md: '12'}} bg='black' p={8}
                           boxShadow="dark-lg" color='white'>
                    <Stack spacing="8">
                        <Metadata
                            name={productName}
                            onNameChange={setProductName}
                            onFileDrop={onFileDropped}
                            productImage={productImage}
                            onProductImageRemoved={setProductImage}
                        />

                        <SourcePhotos
                            sourceImages={sourceImages}
                            onImageRemoved={onSourceImageRemoved}
                            onFileDrop={onFileDropped}
                        />

                        {/*<Stack spacing="6" align="center">*/}
                        {/*    <Stack spacing={{base: '2', md: '3'}} textAlign="center">*/}


                        {/*    </Stack>*/}
                        {/*</Stack>*/}
                        {/*<Stack spacing="6">*/}

                        {/*</Stack>*/}
                    </Stack>
                </Container>
            </Center>


        </CoreModal>
    )
}