import {
    Button,
    Container,
    FormControl,
    FormHelperText,
    FormLabel,
    Heading,
    Input,
    Box,
    Stack,
    Text,
    Center,
    chakra,
} from '@chakra-ui/react'
import {GoogleIcon} from '../provider-icons'
import {useState} from "react";
import {Logo} from "@krfts/product-logo";
import {useAuthStore} from "@krfts/product-state";
import {handleSignUp} from "../../data";

export const SignUp = () => {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)

    const authStore = useAuthStore()

    const signUpClicked = async () => {
        setLoading(true)
        if (await handleSignUp(authStore, email, password)) {
            authStore.setShowAuthModal(false)
            setLoading(false)
        }
    }

    return (
        <Center w='100%' h='100%'>
            <Container py={{base: '12', md: '12'}} bg='black' p={8}
                       boxShadow="dark-lg" color='white'>
                <Stack spacing="8">
                    <Stack spacing="6" align="center">
                        <Logo w='250px'/>
                        <Stack spacing="3" textAlign="center">
                            <Heading size={{base: 'xs', md: 'sm'}}>Create an account</Heading>
                            {/*<Text color="gray.500">Start making the impossible beautifully real</Text>*/}
                        </Stack>
                    </Stack>
                    <Stack spacing="6">
                        <Stack spacing="5">
                            <FormControl isRequired>
                                <FormLabel htmlFor="email">Email</FormLabel>
                                <Input id="email" type="email" value={email} placeholder="Enter your email"
                                       onChange={(e) => setEmail(e.target.value)}/>
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel htmlFor="password">Password</FormLabel>
                                <Input id="password" type="password" value={password} placeholder="********"
                                       onChange={(e) => setPassword(e.target.value)}/>
                                <FormHelperText color="gray.300">At least 8 characters long</FormHelperText>
                            </FormControl>
                        </Stack>
                        <Stack spacing="4">
                            <Button onClick={() => signUpClicked()} isLoading={loading}>Create account</Button>
                            <Button variant="outline" color='white' leftIcon={<GoogleIcon/>} _hover={{ bg: 'gray.700' }}>
                                Sign up with Google
                            </Button>
                        </Stack>
                    </Stack>
                    <Text textStyle="sm" color="gray.500" align='center'>
                        Already have an account? <chakra.span cursor='pointer' textDecoration='underline' onClick={() => authStore.setAuthModalMode('signIn')}>Sign in</chakra.span>
                    </Text>
                </Stack>
            </Container>
        </Center>
    )
}