import axios from "axios";
import {API_BASE_URL, PRODUCT_ENDPOINT} from "@krfts/product-common";

export const createProduct = async (productName, productImage, sourceImages, idToken) => {

    const formData = new FormData();
    const data = new Blob([JSON.stringify({'name': productName})], { type: "application/json" });
    formData.append('json', data)

    formData.append("product-photo", productImage.file, productImage.file.name)
    sourceImages.map((image, i) => {
        const key = `source-photo${i+1}`
        formData.append(key, image.file, image.file.name)
    })

    try {
        const response = await axios.post(API_BASE_URL + PRODUCT_ENDPOINT, formData, {
            headers: {
                'Authorization': `Bearer ${idToken}`,
                'Content-Type': 'multipart/form-data',
            }
        })
        return response;
    } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
    }
}