import {Box, Center, Spacer, HStack, Stack, Container, Button} from '@chakra-ui/react'
import {Logo} from "@krfts/product-logo"
import {UserMenu} from "@krfts/product-user-menu"
import {NavButtons} from "./nav-buttons"
import {useAuthStore} from "@krfts/product-state";

export const NavBar = () => {

    const currentUser = useAuthStore((state) => state.currentUser);
    const authLoading = useAuthStore((state) => state.loading);
    const setShowAuthModal = useAuthStore((state) => state.setShowAuthModal);
    const setAuthModalMode = useAuthStore((state) => state.setAuthModalMode);

    return (
        <Box as="section" w='100%'>
            <Box borderBottomWidth="0px" bg="#101214" position="relative" color='white' w='100%' boxShadow="lg">
                <Container py="2" px='12' minW='80%'>
                    <Stack direction='row' justify="space-between" spacing="20" w='100%'>
                        <Center>
                            <Box w='150px'>
                                <Center>
                                    <Logo/>
                                </Center>
                            </Box>
                        </Center>
                        <HStack spacing="10">
                            <NavButtons/>
                        </HStack>
                        <Spacer/>
                        <HStack spacing={{base: '2', md: '4'}}>
                            { currentUser ?
                                <UserMenu/>
                                :
                                <Button
                                    size='sm'
                                    bg='yellow.300'
                                    _hover={{ bg: 'yellow.100' }}
                                    onClick={() => {
                                        setAuthModalMode('signIn')
                                        setShowAuthModal(true)
                                    }}
                                    isLoading={authLoading}
                                >
                                    Sign In
                                </Button>
                            }
                        </HStack>
                    </Stack>
                </Container>
            </Box>
        </Box>
    )
}