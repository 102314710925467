import {getApps, initializeApp} from 'firebase/app';
import {firebaseConfig} from "@krfts/product-common"
import {create} from 'zustand'
import {getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged, createUserWithEmailAndPassword} from "firebase/auth";

if (getApps().length === 0) { initializeApp(firebaseConfig)}
let auth = getAuth()

export const useAuthStore = create((set) => ({
    currentUser: null,
    signInError: "",
    signUpError: "",
    signOutError: "",
    loading: true,
    showAuthModal: false,
    authModalMode: "signIn",
    setShowAuthModal: (val) => {
        set({showAuthModal: val})
    },
    setAuthModalMode: (val) => {
        set({authModalMode: val})
    },
    signIn: async (credentials) => {
        const {email, password} = credentials
        try {
            await signInWithEmailAndPassword(auth, email, password)
        } catch (error) {
            set({signInError: error.message})
        }
    },
    signUp: async (credentials) => {
        const {email, password} = credentials
        try {
            await createUserWithEmailAndPassword(auth, email, password)
        } catch (error) {
            set({signUpError: error.message})
        }
    },
    signOut: async () => {
        try {
            await signOut(auth)
        } catch (error) {
            set({signOutError: error.message})
        }
    }
}))

onAuthStateChanged(auth, (currentUser) => {
    console.log("AuthStateChanged: ", currentUser)
    useAuthStore.setState({
        currentUser: currentUser ? currentUser : null,
        loading: false
    })
})