export const products = [
    {
        id: '1',
        name: 'ON2 Wood Sofa bed',
        imageUrl:
            'https://media.fds.fi/decor_image/800/ATD_Retail_2024_RFH-Lounge-Chair-RD7_01.jpg',
        imagesGenerated: 20,
        videosGenerated: 16
    },
    {
        id: '2',
        name: 'Buur Lounge chair',
        imageUrl:
            'https://media.fds.fi/decor_image/600/2023_Rar_Sofa_2Seater_Venezia_Off_White_Cellu_Floor_Lamp_Grey_Sculp_Table_Small_Hide_Pouf_Small_Brown.jpg',
        imagesGenerated: 15,
        videosGenerated: 8
    },
    {
        id: '3',
        name: 'Tolix Chair A56',
        imageUrl:
            'https://media.fds.fi/product_image/800/TL10539-00060.jpg',
        imagesGenerated: 9,
        videosGenerated: 9
    },
    {
        id: '1',
        name: 'ON2 Wood Sofa bed',
        imageUrl:
            'https://media.fds.fi/decor_image/800/ATD_Retail_2024_RFH-Lounge-Chair-RD7_01.jpg',
        imagesGenerated: 20,
        videosGenerated: 16
    },
    {
        id: '2',
        name: 'Buur Lounge chair',
        imageUrl:
            'https://media.fds.fi/decor_image/600/2023_Rar_Sofa_2Seater_Venezia_Off_White_Cellu_Floor_Lamp_Grey_Sculp_Table_Small_Hide_Pouf_Small_Brown.jpg',
        imagesGenerated: 15,
        videosGenerated: 8
    },
    {
        id: '3',
        name: 'Tolix Chair A56',
        imageUrl:
            'https://media.fds.fi/product_image/800/TL10539-00060.jpg',
        imagesGenerated: 9,
        videosGenerated: 9
    },
]