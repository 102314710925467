import { Box, Link, SimpleGrid, Stack } from "@chakra-ui/react";
import * as React from "react";
import { FooterHeading } from "./footer-heading";

export const LinkSection = (props) => (
    <SimpleGrid columns={2} {...props} textColor="#fcfcfc" fontSize='xs'>
        <Box minW="130px">
            <FooterHeading mb="4">Product</FooterHeading>
            <Stack>
                <Link>About KRFTS</Link>
                <Link>How to use</Link>
                <Link>For brands</Link>
            </Stack>
        </Box>
        <Box minW="130px">
            <FooterHeading mb="4">Legal</FooterHeading>
            <Stack>
                <Link>Privacy</Link>
                <Link>Terms</Link>
                <Link>License</Link>
            </Stack>
        </Box>
    </SimpleGrid>
);