import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    HStack, Box,
} from '@chakra-ui/react'
import {TextBox} from "@krfts/core-text-box";
import {RemoveButton} from "@krfts/product-ui-library";

export const CoreModal = (props) => {

    const {isOpen, onCloseClicked, size, title, buttonComponents, children, hideHeader} = props;

    return (
        <Modal isOpen={isOpen} onClose={() => onCloseClicked()} size={size ? size : "lg"} isCentered>
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px)'
            />
            <ModalContent bg='transparent' boxShadow={0}>
                {!hideHeader ?
                    <ModalHeader>
                        <HStack justify='space-between'>
                        <TextBox text={title}/>
                        <Box>
                            <RemoveButton size='sm' onClick={onCloseClicked}/>
                        </Box>
                        </HStack>
                    </ModalHeader>
                    : null
                }

                <ModalBody>
                    {children}
                </ModalBody>

                <ModalFooter justifyContent='center'>
                    {buttonComponents ?
                        buttonComponents
                        :
                        null
                    }
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}