import {
    Box,
    FormControl,
    FormLabel,
    Icon,
    HStack,
    Input,
    Square,
    Image
} from '@chakra-ui/react'
import {AssetDropzone} from "@krfts/core-asset-dropzone";
import {IoAdd} from "react-icons/io5";
import {RemoveButton} from "@krfts/product-ui-library";

export const Metadata = (props) => {

    const {name, onNameChange} = props

    return (
        <Box flex="1" w='100%'>
            <FormControl id="product-name">
                <HStack spacing={10} align='top'>
                    <Box>
                        <FormLabel fontSize='sm'>Product photo</FormLabel>

                        {
                            props.productImage ?
                                <Box>
                                    <Box position='relative' boxSize='120px'>
                                        <Image src={props.productImage.imageSrc}/>
                                        <RemoveButton position='absolute' top={-0.5} right={-0.5} onClick={() => {
                                            props.onProductImageRemoved(false)
                                        }}/>
                                    </Box>
                                </Box>
                                :
                                <AssetDropzone onFileDrop={props.onFileDrop} assetType='metadata' boxSize='120px'>
                                    <Square size="10" bg="bg.subtle">
                                        <Icon as={IoAdd} boxSize="10" color="black"/>
                                    </Square>
                                </AssetDropzone>}

                    </Box>

                    <Box w='50%'>
                        <FormControl isRequired>
                            <FormLabel fontSize='sm'>Product name</FormLabel>
                            <Input
                                rounded='0'
                                placeholder='Name of the product'
                                bg='gray.100'
                                color='black'
                                fontSize='sm'
                                value={name}
                                onChange={(e) => onNameChange(e.target.value)}
                            />
                        </FormControl>
                    </Box>
                </HStack>
            </FormControl>
        </Box>
    )
}