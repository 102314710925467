import {
    Button,
    chakra,
    Input,
    Stack,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { FooterHeading } from "./footer-heading";

export const SubscribeSection = (props) => {
    return (
        <chakra.form {...props} onSubmit={(e) => e.preventDefault()}>
            <Stack spacing="4">
                <FooterHeading>Get the latest updates</FooterHeading>
                <Text textColor="#fcfcfc" fontSize='xs'>
                    Get notified when we add new features or have other news.
                </Text>
                <Stack
                    spacing="4"
                    direction={{
                        base: "column",
                        md: "row",
                    }}>
                    <Input
                        bg="white"
                        placeholder="Enter your email"
                        type="email"
                        required
                        focusBorderColor={useColorModeValue("blue.500", "blue.300")}
                        _placeholder={{
                            opacity: 1,
                            color: useColorModeValue("gray.500", "whiteAlpha.700"),
                        }}
                        size='sm'
                    />
                    <Button
                        type="submit"
                        size='sm'
                        // bgGradient="linear(to-r, yellow.300, pink.300)"
                        bg='yellow.300'
                        // _hover={{ bgGradient: "linear(to-r, yellow.200, pink.200)" }}
                        _hover={{ bg: 'yellow.100' }}
                        flexShrink={0}
                        width={{
                            base: "full",
                            md: "auto",
                        }}>
                        {" "}
                        Subscribe
                    </Button>
                </Stack>
            </Stack>
        </chakra.form>
    );
};