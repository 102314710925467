import {Center, VisuallyHidden, VStack, Input, AspectRatio} from '@chakra-ui/react'
import Dropzone from "react-dropzone";


export const AssetDropzone = (props) => {

    return (
        <AspectRatio ratio={1} bg='white' boxSize={props.boxSize}>
            <Dropzone onDrop={(acceptedFiles) => props.onFileDrop(acceptedFiles, props.assetType)}>
                {({getRootProps, getInputProps}) => (
                    <Center px="6" py="4" >
                        <VStack spacing="3" {...getRootProps()}>
                            <VisuallyHidden>
                                <Input
                                    id='productPhoto'
                                    name='productPhoto'
                                    type="file"
                                    {...getInputProps()}
                                />
                            </VisuallyHidden>
                            {props.children}
                        </VStack>
                    </Center>
                )}
            </Dropzone>
        </AspectRatio>
    )
}