import {Box, Flex, Center, Button, VStack} from '@chakra-ui/react'
import {ProductGrid} from "./ui/product-grid";
import {products} from "./data";
import {useState} from "react";
import {TextBox} from "@krfts/core-text-box";
import {NewProductModal} from "./ui/new-product-modal";
import bg_image from './assets/bg_image.jpg'

export const ProductsContent = () => {

    const [showNewProductModal, setShowNewProductModal] = useState(false);

    return (
        <>
            <Box w='100%' h='100%' bgImage={bg_image} bgSize='cover' overflow='auto' position='relative'
                 boxShadow='inset 0 0 0 2000px rgba(0, 0, 0, 0.7)'>
                <VStack w='100%' mt={8} px={20} spacing={12}>
                    <Box w='60%'>
                        <TextBox
                            text='This is a list of your products. Create a new one to generate images and video for or view what you have created for your existing products.'
                        />
                    </Box>
                    <VStack spacing={2}>
                        <Button bg='yellow.300' _hover={{bg: 'yellow.100'}}
                                onClick={() => setShowNewProductModal(true)}>+
                            New Product</Button>
                        <ProductGrid products={products}/>
                    </VStack>
                </VStack>
            </Box>
            <NewProductModal showModal={showNewProductModal} setShowModal={setShowNewProductModal} />
        </>
    )
}