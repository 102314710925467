import {
    Avatar,
    Text,
    Button,
} from '@chakra-ui/react'
import {CoreMenu} from '@krfts/core-menu'
import {NavLink} from "react-router";
import {useAuthStore} from "@krfts/product-state";
import {handleSignOut} from "./data";

export const UserMenu = () => {

    const authStore = useAuthStore()

    return (
        <CoreMenu
            menuButton={<Avatar boxSize="10" name={authStore.currentUser ? authStore.currentUser.email : ""}/>}
            menuItemsAboveDivider={[
                <NavLink to="/account">My Account</NavLink>,
                <NavLink to="/billing">Plan & Billing</NavLink>
            ]}
            menuItemsBelowDivider={[
                <Text onClick={() => handleSignOut(authStore)}>Sign Out</Text>
            ]}
        />
    )
}