import {IconButton} from '@chakra-ui/react'
import {IoClose} from "react-icons/io5";

export const RemoveButton = (props) => {

    return (
        <IconButton
            {...props}
            border='2px solid'
            rounded='0'
            size={props.size ? props.size : 'xs'}
            bg='black'
            color='white'
            variant='ghost'
            _hover={{color: 'white'}}
            _active={{}}
        >
            <IoClose size='20'/>
        </IconButton>
    )
}