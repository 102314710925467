import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Button,
} from '@chakra-ui/react'

export const CoreMenu = (props) => {

    const {menuButton, menuItemsAboveDivider, menuItemsBelowDivider} = props

    return (
        <Menu>
            <MenuButton as={Button} variant='ghost' _hover={{}} _active={{}}>
                {menuButton}
            </MenuButton>
            <MenuList color='black'>
                {menuItemsAboveDivider.map((item, i) => (
                    <MenuItem key={i}>{item}</MenuItem>
                ))}
                <MenuDivider/>
                {menuItemsBelowDivider.map((item, i) => (
                    <MenuItem key={i}>{item}</MenuItem>
                ))}
            </MenuList>
        </Menu>
    )
}