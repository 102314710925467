// import {ComfyProvider} from "./comfy/ComfyProvider";
import {Routes, Route} from "react-router";
import {Studio} from "./pages/Studio";
// import {Dashboard} from "./pages/Dashboard";
import {Products} from "./pages/Products";

// import {MyAccount} from "./pages/MyAccount";

import {Home} from "./pages/Home";

function App() {

    return (
        // <ComfyProvider>

        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/products" element={<Products/> }/>
            <Route path="/studio" element={<Studio/> }/>
            {/*<Route path="/account" element={<Protected><MyAccount/></Protected>}/>*/}
        </Routes>

    );
}

export default App;
